:root {
  --animate-duration: 0.25s;
  --brand-primary-color: #fc017f;
  --brand-darken-primary-color: #ad0058;
  --brand-lighten-primary-color: #ffeaf4;
  --brand-secondary-color: #484848;
  --brand-darken-secondary-color: #202020;
  --brand-tertiary-color: #eaf2ff;
  --brand-darken-tertiary-color: #dfe7f5;
  --brand-background-color: #e7f1fa;
  --focus-border-color: #004de2;
  --text-primary-color: #484848;
  --text-secondary-color: #666666;
  --text-tertiary-color: #7588a7;
  --text-darken-tertiary-color: #5f718d;
  --text-muted-color: #919191;
  --text-disabled-color: #a9a9a9;
  --inactive-form-label-color: #b4b4b4;
  --bs-primary-rgb: 252, 1, 127;
  --skeleton-color: #ededed;
  --link-color: #2a4e96;
  --link-active-color: #ad0058;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: var(--text-primary-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background: #f6fcff; */
}

a,
.link {
  text-decoration: underline;
  color: var(--link-color);
}

a:hover,
.link:hover {
  text-decoration: none;
  color: var(--brand-primary-color);
}

a:active,
.link:active {
  color: var(--link-active-color);
}

.link-white {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
}

.link-white:hover {
  color: #ffffff;
  text-decoration: none;
}

.link-white:active {
  color: var(--brand-tertiary-color);
}

label {
  user-select: none;
}

select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5.6719 6L0.759883 -6.52533e-07L10.5839 -4.68497e-08L5.6719 6Z' /%3E%3C/svg%3E");
  background-position: calc(100%) calc(100% - 3px);
  background-size: 24px 24px, 24px 24px;
  background-repeat: no-repeat;
}

@-webkit-keyframes rhinoFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes rhinoFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.loading-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.rhino_animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rhino_animate__fadeIn {
  -webkit-animation-name: rhinoFadeIn;
  animation-name: rhinoFadeIn;
}

.rhino_animate__fadeOut {
  -webkit-animation-name: rhinoFadeOut;
  animation-name: rhinoFadeOut;
}

.rhino-shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
}

.container-xs {
  max-width: 320px;
}

.container-sm {
  max-width: 540px;
}

.text-right {
  text-align: right !important;
}

.text-large {
  font-size: 1.2rem;
}

.text-medium {
  font-size: 0.8rem;
}

.text-small {
  font-size: 0.7rem;
}

.text-micro {
  font-size: 0.5rem;
}

.text-primary {
  color: var(--brand-primary-color);
}

.text-muted {
  color: var(--text-muted-color) !important;
}

.text-disabled {
  color: var(--text-disabled-color) !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.float-right {
  float: right;
}

.w-100px {
  width: 100px;
}

.max-w-400px {
  max-width: 400px;
}

.max-w-600px {
  max-width: 600px;
}

.max-w-700px {
  max-width: 700px;
}

.h-100px {
  height: 100px;
}

.h-200px {
  height: 200px;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.user-select-none {
  user-select: none;
}

.no-wrap {
  white-space: nowrap;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.aspect-ratio-1-1 {
  aspect-ratio: 1/1;
}

.aspect-ratio-3-4 {
  aspect-ratio: 3/4;
}

.aspect-ratio-4-3 {
  aspect-ratio: 4/3;
}

.btn {
  border-radius: 1.5rem;
  padding: 0.75rem;
}

.bg-primary {
  background-color: var(--brand-primary-color);
}

.bg-secondary {
  background-color: var(--brand-secondary-color);
}

.bg-tertiary {
  background-color: var(--brand-tertiary-color);
}

.bg-darken-primary {
  background-color: var(--brand-darken-primary-color);
}

.bg-lighten-primary {
  background-color: var(--brand-lighten-primary-color);
}

.btn-primary {
  background-color: var(--brand-primary-color);
  color: #fff;
  border-color: var(--brand-primary-color);
}

.btn-primary:hover {
  background-color: var(--brand-darken-primary-color);
  border-color: var(--brand-darken-primary-color);
}

.btn-tertiary {
  background-color: var(--brand-tertiary-color);
  color: var(--text-tertiary-color);
  border-color: var(--brand-tertiary-color);
}

.btn-tertiary:hover {
  background-color: var(--brand-darken-tertiary-color);
  color: var(--text-tertiary-color);
  border-color: var(--brand-darken-tertiary-color);
}

.btn-xs {
  height: 1.75rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:disabled,
.btn-primary:active:focus {
  background-color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
  box-shadow: none;
}

.btn-primary:disabled {
  opacity: 0.15;
}

.btn-outline-primary {
  color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
}

.btn-outline-primary:disabled {
  color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
  opacity: 0.4;
}

.btn-outline-primary:active {
  color: white;
  border-color: var(--brand-darken-primary-color);
  background-color: var(--brand-darken-primary-color);
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-primary:hover {
  color: white;
  border-color: var(--brand-primary-color);
  background-color: var(--brand-primary-color);
}

.btn-outline-primary:focus,
.btn-outline-primary:focus-visible {
  color: white;
  border-color: var(--brand-darken-primary-color);
  background-color: var(--brand-darken-primary-color);
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-secondary {
  color: var(--brand-secondary-color);
  border-color: var(--brand-secondary-color);
}

.btn-outline-secondary:disabled {
  color: var(--brand-secondary-color);
  border-color: var(--brand-secondary-color);
  opacity: 0.4;
}

.btn-outline-secondary:active {
  border-color: var(--brand-secondary-color);
  color: var(--brand-secondary-color);
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-secondary:hover {
  color: var(--brand-darken-secondary-color);
  border-color: var(--brand-darken-secondary-color);
  background-color: transparent;
}

.btn-outline-secondary:focus,
.btn-outline-secondary:focus-visible {
  color: var(--brand-darken-secondary-color);
  border-color: var(--brand-darken-secondary-color);
  outline: none !important;
  box-shadow: none !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-primary {
  border-color: var(--brand-primary-color) !important;
}

.inline-form-control-sm {
  background-color: #ffffff;
  background-image: none;
  border: none;
  color: var(--text-primary-color);
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.inline-form-control-sm:focus-visible {
  outline: none;
}

.inline-form-control {
  border: none;
  border-bottom: 1px solid var(--text-tertiary-color);
  color: var(--text-primary-color);
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.inline-form-control:focus-visible {
  outline: none;
  border-bottom: 1px solid var(--brand-primary-color);
}

.form-control {
  border-radius: 0.75rem !important;
  padding: 0.75rem;
}

.form-check-input:checked {
  background-color: var(--brand-primary-color);
  border-color: var(--brand-primary-color);
}

.form-check-input.check-smaller {
  width: 0.7em;
  height: 0.7em;
  border-radius: 0.1em;
}

.form-check-input.check-darker:not(:disabled) {
  border-color: var(--brand-primary-color);
}

.form-check-input.check-darker:checked {
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--focus-border-color);
}

.dropdown-item {
  background-color: #ffffff;
}

.dropdown-item:hover {
  background-color: #ffffff;
  color: var(--brand-primary-color);
}

.dropdown-item:active {
  background-color: #ffffff;
  color: var(--brand-darken-primary-color);
}

.rounded {
  border-radius: 0.75rem !important;
}

.grayscale-0 {
  filter: grayscale(0%);
}

.grayscale-1 {
  filter: grayscale(100%);
}

.top-notification-bar {
  background-color: var(--text-darken-tertiary-color);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

#section-header .logo {
  height: 50px;
}

#section-header .greeting {
  height: 15px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
}

#section-stepper .stepper-container {
  position: relative;
  user-select: none;
  height: 50px;
}

#section-stepper hr {
  margin: 0;
  padding: 0;
  width: 100%;
  top: 8px;
  z-index: -1;
  position: absolute;
}

#section-stepper .back-btn {
  position: absolute;
  top: 18.5px;
  left: -2px;
  transform: scale(0.7);
  z-index: 100;
}

#section-stepper .steps {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#section-stepper .step {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  width: 20%;
  cursor: default;
}

#section-stepper .step-current {
  color: var(--brand-primary-color);
}

#section-page h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  display: none;
}

#section-page h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 24px;
}

.content-container {
  background: radial-gradient(
    78.54% 175.85% at 39.35% 78.72%,
    #f5f9fd 0%,
    #f6fcff 100%
  );
}

.plan-disclaimer {
  padding: 0.33rem 0rem 0.33rem 0rem;
  font-size: 0.8rem;
  color: var(--text-darken-tertiary-color);
  background-color: var(--brand-tertiary-color);
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.plan-container {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.plan-container .plan {
  border-bottom: 1px solid #dee2e6;
  padding-right: 0rem;
  padding-left: 0rem;
}

.plan-container .plan:last-child {
  border-bottom: none;
  padding-right: 0rem;
}

.plan-container h2,
.inline-form-control {
  font-size: 30px;
  font-weight: 600;
  display: inline;
}

.plan-container h4 {
  font-size: 15px;
  font-weight: 600;
  display: inline;
}

.plan-label {
  position: absolute;
  padding: 0rem 0.5rem 0.125rem 0.5rem;
  font-weight: 600;
  border-radius: 0.4rem;
  transform: scale(0.8);
  border: none;
}

.plan-label.alert {
  background-color: var(--skeleton-color);
  bottom: 1rem;
  right: -0.5rem;
  margin: 0 !important;
}

.plan-label.coming-soon {
  background-color: var(--skeleton-color);
  bottom: 1rem;
  right: -0.5rem;
}

.plan-label.lock {
  color: white;
  bottom: 1rem;
  right: -0.5rem;
  background-color: var(--text-primary-color);
}

.plan-label.savings {
  color: var(--text-tertiary-color);
  border: 2px solid var(--brand-tertiary-color);
  background-color: var(--brand-tertiary-color);
}

.skeleton-text {
  background-color: var(--skeleton-color);
  color: var(--skeleton-color);
  display: inline-block;
  user-select: none;
}

.battery-container {
  border-radius: 0.5rem;
  display: block;
  width: 100%;
}

.battery-container h2 {
  font-size: 30px;
  font-weight: 600;
  display: inline;
}

.battery-container h4 {
  font-size: 15px;
  font-weight: 600;
  display: inline;
}

footer {
  width: 100vw;
  height: 50px;
  /* background-color: white; */
  margin: 0;
  position: fixed;
  bottom: 0;
  z-index: 99999;
}

.tippy-popper,
.tippy-tooltip,
.tippy-tooltip [x-circle] {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: unset;
}

.form-floating label {
  color: #b4b4b4;
  padding-left: 25px;
}
.form-floating input:not(:placeholder-shown) {
  color: #414141;
}

.form-floating input:not(:placeholder-shown) ~ label {
  color: #414141;
}

.form-floating > .form-control:focus ~ label {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  /* box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.1); */
}

.form-floating select:not(:placeholder-shown) {
  color: #414141;
}

.form-floating select:not(:placeholder-shown) ~ label {
  color: #414141;
}

.form-floating .form-control:focus {
  border-color: var(--brand-primary-color);
}

/* < md */
@media (max-width: 768px) {
  .plan-disclaimer {
    border-bottom: none !important;
  }

  .plan-container {
    border-top: none !important;
  }
}

/* md */
@media (min-width: 768px) {
  .plan-label {
    transform: scale(1);
  }

  .plan-label.alert {
    position: relative;
    display: inline;
    left: 0.5rem;
    top: -0.35rem;
  }

  .plan-label.coming-soon {
    bottom: unset;
    left: unset;
    top: -0.5rem;
    right: 0rem;
  }

  .plan-label.lock {
    bottom: unset;
    right: unset;
    top: 4.75rem;
    left: 0rem;
  }

  .plan-disclaimer {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-top: none !important;
  }

  .plan-container {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom: none !important;
  }

  .plan-container .plan {
    border-right: 1px solid #dee2e6;
    border-bottom: unset;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .plan-container .plan:first-child {
    padding-left: 0rem;
  }

  .plan-container .plan:last-child {
    border-right: none;
  }

  .float-md-right {
    float: right !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  #section-page h1 {
    font-size: 36px;
    display: block;
  }

  #section-stepper .steps {
    width: 75%;
  }

  #section-stepper .back-btn {
    top: 18px;
    transform: scale(0.9);
  }

  footer {
    height: 50px;
  }
}

/* lg */
@media (min-width: 992px) {
  .h-lg-100px {
    height: 100px !important;
  }

  .h-lg-200px {
    height: 200px !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-33 {
    width: 33% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-unset {
    width: unset !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .plan-container h2,
  .inline-form-control {
    font-size: 30px;
  }

  .plan-container h4 {
    font-size: 19px;
  }

  .battery-container {
    display: unset;
    width: unset;
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* xl */
@media (min-width: 1400px) {
  .w-xl-33 {
    width: 33% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-floating > .form-control {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-floating label {
  color: var(--inactive-form-label-color);
  padding-left: 1.5rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label,
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  opacity: 1;
  transform: scale(0.85) translateY(-1rem) translateX(2rem);
  padding: 0.25rem 0.5rem;
  height: 1.75rem;
  background-color: #ffffff !important;
  font-size: 0.82rem;
}

select:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: #ffffff !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 0.4;
}

.grecaptcha-badge {
  z-index: 999999;
}

.logo-loading {
  height: 100px;
}

.show-hide-password {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: 1.75rem;
  z-index: 9999;
}

.show-password-button {
  background: none;
  border: unset;
}

.eye-icon {
  height: 1rem;
  filter: invert(81%) sepia(0%) saturate(426%) hue-rotate(205deg)
    brightness(92%) contrast(85%);
}

.text-bold {
  font-weight: 600;
}

.divider {
  height: 1px;
  background-color: #ccc;
}
